<script setup lang="ts">
import { computed } from "vue"
import { useRouter } from "vue-router"
import { type NotificationPayload, AlertType } from "~/lib/notify.js"

import WarningIcon from "~icons/ph/warning-circle-light"
import CheckCircleIcon from "~icons/recital/check-circle"
import CloseCircleIcon from "~icons/recital/close-circle"
import QuestionIcon from "~icons/recital/question"

const props = defineProps<{ notification: NotificationPayload }>()

const emit = defineEmits<{
  close: []
}>()

const router = useRouter()

const iconName = computed(() => {
  switch (props.notification.type) {
    case AlertType.WARNING: {
      return WarningIcon
    }
    case AlertType.INFO: {
      return QuestionIcon
    }
    case AlertType.ERROR: {
      return CloseCircleIcon
    }
    case AlertType.SUCCESS: {
      return CheckCircleIcon
    }
    default: {
      return QuestionIcon
    }
  }
})

const bgForType = computed(() => {
  return {
    "bg-warning-300": props.notification.type === AlertType.WARNING,
    "bg-blue-200": props.notification.type === AlertType.INFO,
    "bg-fail-300": props.notification.type === AlertType.ERROR,
    "bg-success-300": props.notification.type === AlertType.SUCCESS,
  }
})

function onClick(e: MouseEvent) {
  if (!props.notification.action) throw new Error("onClick is only for action")

  props.notification.action?.onClick?.(e, () => emit("close"))

  const { url } = props.notification.action

  if (url) {
    router.push(url)
  }
}
</script>

<template>
  <div class="mx-auto w-full rounded-md p-4 md:w-3/4" :class="bgForType">
    <div class="flex">
      <div class="">
        <component
          :is="iconName"
          class="h-5 w-5 cursor-pointer rounded-full stroke-current"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 flex-1 align-middle">
        <div class="flex justify-between text-sm">
          <p>
            <span v-if="notification.title" class="font-medium">{{ notification.title }}</span>
            {{ notification.text }}
          </p>
          <div class="flex flex-0 flex-row">
            <p v-if="notification.action">
              <button
                type="button"
                class="mr-5 cursor-pointer items-start whitespace-nowrap text-blue-700"
                sm
                @click="onClick"
              >
                {{ notification.action.text }}
              </button>
            </p>
            <p v-if="notification.action || notification.duration > 5000" @click="emit('close')">
              <button type="button" href="#" class="cursor-pointer text-blue-700">Dismiss</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
